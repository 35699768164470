// Skills.js

import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

const Skills = ({ skills, color, bgcolor }) => {
    return skills.map((skill, idx) => (
        <Chip
            label={skill}
            key={idx}
            variant="outlined"
            sx={{
                marginRight: "16px",
                marginTop: "8px",
                fontSize: "13px",
                fontFamily: "RobotoMono",
                color: color && color,
                background: bgcolor && bgcolor,
            }}
            onClick={() => ""}
        />
    ));
};

export default Skills;
