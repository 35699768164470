// ProjectDescription.js

import React from "react";
import Box from "@mui/material/Box";

const Description = ({ descriptions,name }) => {
    return descriptions.map((desc, idx) => (
        <div style={{ position: "relative" }} key={idx}>
            <Box className={"role_description"}>
                <Box className={"role_content"}>
                    {desc}
                </Box>
            </Box>
        </div>
    ));
};

export default React.memo(Description);
