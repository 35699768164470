import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ViewSkeleton from "../../components/Skeleton/ViewSkeleton";

const CertificationDetails = ({ certification, title }) => {
    return (
        <Grid container spacing={2} sx={{ paddingTop: "40px" }}>
            <Grid item xs={12} md={3} className="employment_role" sx={{ paddingBottom: 2 }}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                {certification.map(({ certificatName, certificateProvider, validFrom, validTo, certificateStatus }, idx) => (
                    <Box key={idx} sx={{ marginBottom: 2 }}>
                        <Box className="role_description">
                            <Typography variant="h6" className="role_position">
                                <ViewSkeleton content={certificatName} height={20} width="80%" marginBottom={1} />
                            </Typography>
                            <Typography variant="subtitle1" className="subheading">
                                <ViewSkeleton content={certificateProvider} height={20} width="80%" marginBottom={1} />
                            </Typography>
                            <Typography variant="subtitle2" className="subheading">
                                <ViewSkeleton
                                    content={`${validFrom} - ${certificateStatus ? "PRESENT" : validTo}`}
                                    height={15}
                                    width="50%"
                                />
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
};

export default React.memo(CertificationDetails);
