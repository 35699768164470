import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const ResumeURL = 'https://drive.google.com/uc?export=download&id=1HCmQz9nW7DQYpXjsjmg9DYuT7ndDoOlH';

const ResumeDownload = () => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = ResumeURL;
        link.setAttribute('download', 'Resume.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Box pt={2} className={"role_content"}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleDownload}
            >
                Download Resume
            </Button>
        </Box>
    );
};

export default ResumeDownload;
