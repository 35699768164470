import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const CustomSkeleton = ({ variant, height, width, marginBottom }) => {
  const variantStatus = variant === "image" || variant === "video" ? "rectangular" : "text";

  return (
    <Box sx={{ width, height, marginBottom }}>
      <Skeleton animation="wave" variant={variantStatus} width="100%" height="100%" />
    </Box>
  );
};

export default CustomSkeleton;
