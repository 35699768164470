import React, { useState, useEffect } from "react";
import CustomSkeleton from "./CustomSkeleton";
import Box from "@mui/material/Box";

const ViewSkeleton = ({ content, height, width, marginBottom, variant }) => {
  const [shouldRenderContent, setShouldRenderContent] = useState(false);

  useEffect(() => {
    // Simulate a delay of 2 seconds before rendering content
    const delay = setTimeout(() => {
      setShouldRenderContent(true);
    }, 1000); // Adjust delay as needed

    // Cleanup the timeout on component unmount
    return () => clearTimeout(delay);
  }, []);

  return shouldRenderContent ? (
    <Box>{content}</Box>
  ) : (
    <CustomSkeleton height={height} width={width} marginBottom={marginBottom} variant={variant} />
  );
};

export default ViewSkeleton;
