import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import Skills from "./Skills";
import ViewSkeleton from "./Skeleton/ViewSkeleton";
import ResumeDownload from "./ResumeDownload";

const Header = ({ name, experience, objective, skills, profilePic, email, mobile, ResumeURL }) => {
    return (
        <Grid container spacing={2} sx={{ width: "100%", padding: "20px" }}>
            <Grid item xs={12} md={3}>
                <Box className="image_container" sx={{ textAlign: "center" }}>
                    <img alt="profile" src={profilePic} style={{ width: "100%", height: "auto", borderRadius: "50%" }} />
                </Box>
            </Grid>
            <Grid item xs={12} md={9} display="flex" flexDirection="column" justifyContent="space-between">
                <Box>
                    <Typography variant="h4" className="heading">
                        <ViewSkeleton
                            content={name}
                            height={25}
                            width="80%"
                        />
                    </Typography>
                    <Typography variant="h6" className="subheading" sx={{ pt: 1 }}>
                        <ViewSkeleton
                            content={experience}
                            height={15}
                            width="50%"
                        />
                    </Typography>
                    <Typography variant="body1" className="role_content" sx={{ pt: 2 }}>
                        <ViewSkeleton
                            content={objective}
                            height={60}
                            width="100%"
                        />
                    </Typography>
                    <Typography variant="body1" className="role_content" sx={{ pt: 2 }}>
                        Email: {email}
                    </Typography>
                    <Typography variant="body1" className="role_content" sx={{ pt: 2 }}>
                        Mobile Number: +91-{mobile}
                    </Typography>
                    <Box sx={{ pt: 2 }}>
                        <ResumeDownload />
                    </Box>
                </Box>
                <Box sx={{ pt: 4 }}>
                    <Skills skills={skills} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default React.memo(Header);
