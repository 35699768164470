import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Description from "../projects/ProjectDescription";
import ViewSkeleton from "../Skeleton/ViewSkeleton";
import Typography from "@mui/material/Typography";

const EmployeeList = ({ projects, title }) => {
    return (
        <Grid container spacing={2} sx={{ paddingTop: "40px" }}>
            <Grid item xs={12} md={3} className="employment_role" sx={{ paddingBottom: 2 }}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                {projects.map(({ description, designation, companyName, technologies, startYear, EndYear, presentEmployee }, idx) => (
                    <Box key={idx} sx={{ marginBottom: 2 }}>
                        <Box className="role_description">
                            <Typography variant="h6" className="role_position">
                                <ViewSkeleton content={designation} height={20} width="80%" marginBottom={1} />
                            </Typography>
                            <Typography variant="subtitle1" className="subheading">
                                <ViewSkeleton
                                    content={`${companyName} - ${startYear} - ${presentEmployee ? "PRESENT" : EndYear}`}
                                    height={15}
                                    width="50%"
                                />
                            </Typography>
                            <ViewSkeleton
                                content={<Description descriptions={description} name={designation} />}
                                height={60}
                                width="100%"
                            />
                        </Box>
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
};

export default React.memo(EmployeeList);
