import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skills from "../Skills";
import Typography from "@mui/material/Typography";
import { toCamelCase } from '../../utils/helpers';

const FrameWorkLanguageSkills = ({ projects, title }) => {
    return (
        <Grid container spacing={2} sx={{ paddingTop: "40px" }}>
            <Grid item xs={12} md={3} className="employment_role" sx={{ paddingBottom: 2 }}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                {Object.entries(projects).map(([category, skills]) => (
                    <Box key={category} sx={{ marginBottom: 2 }}>
                        <Box className="role_description">
                            <Typography variant="h6" className="role_position">
                                {toCamelCase(category)}
                            </Typography>
                            <Skills skills={skills} color={"green"} />
                        </Box>
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
};

export default React.memo(FrameWorkLanguageSkills);
