import { useState } from "react";
import { CircularProgress, styled, Box, Paper, Container, Typography, Grid } from "@mui/material";
import "./App.css";
import resumeData from "./data/resumeData.json";
import Header from "./components/Header";
import ProjectsList from "./components/projects/ProjectsList";
import EmployeeList from "./components/Employment/EmployeeList";
import FrameWorkLanguageSkills from "./components/LanguageSkills/FrameWorkLanguageSkills";
import EducationDetails from "./components/Education/EducationDetails";
import CertificationDetails from "./components/Certification/CertificationDetails";
import Logo from './assets/images/hglogo.png';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  margin: theme.spacing(1),
}));

const MainContainer = styled(Container)(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(4),
  },
}));

const Footer = styled("footer")(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  textAlign: "center",
  backgroundColor: "#f0f0f0",
}));

const HeaderContainer = {
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 1000,
  background: "#f57d05"
};

function App() {
  const {
    name,
    experience,
    objective,
    ResumeURL,
    skills,
    projects,
    companyEmployment,
    education,
    courses,
    personalDetails,
    profile_pic,
    LanguageSkills,
    Email,
    Mobile,
    certification
  } = resumeData;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  return (
    <div>
      <MainContainer maxWidth="xl" className="main_container">
        <Box sx={{ flexGrow: 1, border: "3px solid beige" }}>
          <Header
            name={name}
            experience={experience}
            objective={objective}
            skills={skills}
            profilePic={profile_pic}
            email={Email}
            mobile={Mobile}
            ResumeURL={ResumeURL}
          />
          <Box sx={{ padding: "0px 20px" }}>
            <EmployeeList projects={companyEmployment} title={'Professional Experience'} />
            <ProjectsList projects={projects} title={"Project"} />
            <FrameWorkLanguageSkills projects={LanguageSkills} title={'Skills'} />
            <EducationDetails education={education} title={"Education"} />
            <CertificationDetails certification={certification} title={"Certifications"} />
          </Box>
        </Box>
      </MainContainer>

      <Footer>
        <Typography variant="body2" color="textSecondary">
          © {new Date().getFullYear()} Himanshu Gautam 
        </Typography>
      </Footer>
    </div>
  );
}

export default App;
